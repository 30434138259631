.modulesButton {
  text-decoration: none;
  width: 170px;
  height: 45px;
  border: 1px solid #2e709e;
  border-radius: 22.5px;
  font-family: Regular;
  font-size: 16px;
  color: #2e709e;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
    background-color: #2e709e;
  }
}

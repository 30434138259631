@import "./modules/button/button";
@font-face {
  font-family: Bold; /* Имя шрифта */
  src: url(/fonts/Montserrat-Bold.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: Regular; /* Имя шрифта */
  src: url(/fonts/Montserrat-Regular.ttf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: Medium; /* Имя шрифта */
  src: url(/fonts/Montserrat-Medium.otf); /* Путь к файлу со шрифтом */
}
@font-face {
  font-family: Italic; /* Имя шрифта */
  src: url(/fonts/Montserrat-Italic.otf); /* Путь к файлу со шрифтом */
}

@font-face {
  font-family: SemiBold; /* Имя шрифта */
  src: url(/fonts/Montserrat-SemiBold.ttf); /* Путь к файлу со шрифтом */
}
body {
  margin: 0;
}

.main {
  &__bcg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url("../img/main/bg.jpg");
    background-size: cover;
    background-position: center;
  }
  .body {
    max-width: 1500px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
    box-sizing: border-box;
    font-family: Medium;
    font-size: 20px;
    color: #252525;
    &__section {
      &_center {
        img {
          transform: translateX(-57px);
        }
      }
      &_right {
        text-align: right;
      }
    }
    .centerSection img {
    }

    .logo {
      display: inline-block;
      height: 50px;
      img {
        height: 100%;
      }
    }
    .title {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .bttn {
      margin-bottom: 30px;
      display: inline-block;
    }
  }
  .footer {
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding-bottom: 40px;
    width: 100%;
    .bars {
      margin-bottom: 30px;
    }
    .grass {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: -4px;
    }
    .year {
      font-size: 20px;
      font-family: Medium;
      color: #252525;
    }
  }
}

@charset "UTF-8";
.modulesButton {
  text-decoration: none;
  width: 170px;
  height: 45px;
  border: 1px solid #2e709e;
  border-radius: 22.5px;
  font-family: Regular;
  font-size: 16px;
  color: #2e709e;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modulesButton:hover {
    color: white;
    background-color: #2e709e; }

@font-face {
  font-family: Bold;
  /* Имя шрифта */
  src: url(/fonts/Montserrat-Bold.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Regular;
  /* Имя шрифта */
  src: url(/fonts/Montserrat-Regular.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Medium;
  /* Имя шрифта */
  src: url(/fonts/Montserrat-Medium.otf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Italic;
  /* Имя шрифта */
  src: url(/fonts/Montserrat-Italic.otf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: SemiBold;
  /* Имя шрифта */
  src: url(/fonts/Montserrat-SemiBold.ttf);
  /* Путь к файлу со шрифтом */ }

body {
  margin: 0; }

.main__bcg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../img/main/bg.jpg");
  background-size: cover;
  background-position: center; }

.main .body {
  max-width: 1500px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  box-sizing: border-box;
  font-family: Medium;
  font-size: 20px;
  color: #252525; }
  .main .body__section_center img {
    transform: translateX(-57px); }
  .main .body__section_right {
    text-align: right; }
  .main .body .logo {
    display: inline-block;
    height: 50px; }
    .main .body .logo img {
      height: 100%; }
  .main .body .title {
    margin-bottom: 30px;
    margin-top: 30px; }
  .main .body .bttn {
    margin-bottom: 30px;
    display: inline-block; }

.main .footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
  width: 100%; }
  .main .footer .bars {
    margin-bottom: 30px; }
  .main .footer .grass {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -4px; }
  .main .footer .year {
    font-size: 20px;
    font-family: Medium;
    color: #252525; }
